import extraCostsListTemplate from '@templates/components/molecules/extra-costs-list.html.njk';
import parseStringAsHtml from '@naturehouse/nh-essentials/lib/html/parser';
import TranslationManager from '../../common/TranslationManager';
import { PriceListExtraCost } from '../../collections/price-list/PriceListCollection';

export type ExtraCostsListRendererTranslations = {
    serviceFee: string;
    priceIsIncluding: string;
    priceIncludes: string;
    additionalCosts: string;
    optionalCosts: string;
};

export type ExtraCostsListRendererProps = {
    includedCosts: string[];
    afterStayCosts: PriceListExtraCost[];
    optionalCosts: PriceListExtraCost[];
    serviceFee: number;
};

export class ExtraCostsListRenderer {
    public async render(props: ExtraCostsListRendererProps): Promise<string> {
        const translations: ExtraCostsListRendererTranslations = await this.#getTranslations();
        const template = parseStringAsHtml(
            extraCostsListTemplate.render({
                ...props,
                translations
            }),
            'div'
        );

        return template.outerHTML;
    }

    async #getTranslations(): Promise<ExtraCostsListRendererTranslations> {
        const translationManager = await TranslationManager.getInstance();
        return {
            serviceFee: translationManager.translate('service_fee.label'),
            priceIsIncluding: translationManager.translate('price_is_including.label'),
            priceIncludes: translationManager.translate('price_includes.label'),
            additionalCosts: translationManager.translate('additional_costs.label'),
            optionalCosts: translationManager.translate('optional_costs.label')
        };
    }
}

export default new ExtraCostsListRenderer();
