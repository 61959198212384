import DatePickerCalendar from '../../calendar/webComponents/DatePickerCalendar';

export default class ClearCalendarHandler {
    readonly #modalElement: HTMLElement;

    readonly #arrivalDateInput: HTMLInputElement;

    readonly #departureDateInput: HTMLInputElement;

    readonly #datepickerCalendar: DatePickerCalendar;

    constructor(
        arrivalDateInput: HTMLInputElement,
        departureDateInput: HTMLInputElement,
        modalElement: HTMLElement,
        datepickerCalendar: DatePickerCalendar
    ) {
        this.#arrivalDateInput = arrivalDateInput;
        this.#departureDateInput = departureDateInput;
        this.#modalElement = modalElement;
        this.#datepickerCalendar = datepickerCalendar;
    }

    public initialize(): void {
        for (const button of this.#setButtons()) {
            button.addEventListener('click', this.#clearCalendar.bind(this));
        }
    }

    #clearCalendar(): void {
        this.#datepickerCalendar.clearSelection();
        this.#departureDateInput.dispatchEvent(new Event('clear'));
        this.#arrivalDateInput.dispatchEvent(new Event('clear'));
    }

    #setButtons(): HTMLButtonElement[] {
        const modalClearButtons: HTMLButtonElement[] = Array.from(
            this.#modalElement.querySelectorAll('[data-role="calendar-clear"]')
        );

        const buttons = [...modalClearButtons];

        const arrivalDateFieldClearButton: HTMLButtonElement | null =
            this.#modalElement.querySelector('[data-name="arrivalDate"] [data-role="clear"]');

        if (arrivalDateFieldClearButton) {
            buttons.push(arrivalDateFieldClearButton);
        }

        return buttons;
    }
}
