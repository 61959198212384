import convertFormdataToJsonObject from '../../util/objects';
import PriceListModel, { type PriceListApiSearchParams } from './PriceListModel';

type DetailPriceListFormDataType = {
    'price_calculation[arrivalDate]': string;
    'price_calculation[departureDate]': string;
    'price_calculation[adults]': number;
    'price_calculation[children]': number;
    'price_calculation[babies]': number;
    'price_calculation[pets]': number;
};

export default class DetailPriceListModel implements PriceListModel {
    public static parseToSearchParams(formData: FormData): URLSearchParams {
        const data = convertFormdataToJsonObject(formData) as DetailPriceListFormDataType;

        const params: PriceListApiSearchParams = {
            arrivalDate: data['price_calculation[arrivalDate]'] ?? '',
            departureDate: data['price_calculation[departureDate]'] ?? '',
            numberOfAdults: (data['price_calculation[adults]'] ?? 0).toString(),
            numberOfChildren: (data['price_calculation[children]'] ?? 0).toString(),
            numberOfBabies: (data['price_calculation[babies]'] ?? 0).toString(),
            numberOfPets: (data['price_calculation[pets]'] ?? 0).toString(),
            insurance: '0'
        };

        return new URLSearchParams(params);
    }
}
