import { Loader } from '@googlemaps/js-api-loader';
import L, {
    GridLayer,
    LatLng,
    LatLngBounds,
    Layer,
    LeafletEvent,
    LeafletMouseEvent,
    Marker
} from 'leaflet';
import googleMapEvents from '../../../events/googleMapEvents';
import { instantiateMapLoader } from '../../../util/mapsHelper';
import MapMarkerCreator from '../../search/MapMarkerCreator';
import '../../../../css/components/_maps.pcss';

interface TileLayerInterface {
    Satellite: Layer;
    GoogleMapsRoadmap: GridLayer;
    GoogleMapsSatellite: GridLayer;
}

export default class MapsComponent {
    protected Leaflet: typeof L | undefined = undefined;

    protected context: ContextInterface;

    #initialized = false;

    protected latitudeField: HTMLInputElement | null = null;

    protected longitudeField: HTMLInputElement | null = null;

    #map: L.Map | undefined;

    readonly #loader: Loader;

    constructor(context: ContextInterface) {
        this.context = context;
        this.#loader = instantiateMapLoader(this.context.bindings.gmapsKey);
    }

    public onInit(): void {
        const initializeButton: HTMLButtonElement | null =
            this.context.element.querySelector('[data-initialize-map]');

        if (!initializeButton) return;

        initializeButton.addEventListener('click', this.initializeMap.bind(this));
    }

    protected initializeMap(): void {
        this.#loader.loadCallback(this.initialize.bind(this));

        document.addEventListener(googleMapEvents.initializeMap, () => {
            this.initialize();
        });
    }

    async initialize(): Promise<void> {
        if (this.#initialized) {
            return;
        }

        this.Leaflet = await this.initializeLeaflet();

        const tileLayers = this.getTileLayers();
        const markers = this.getMarkers();

        if (!tileLayers) {
            return;
        }

        const latLon: LatLng = this.Leaflet.latLng(
            this.context.bindings.latitude,
            this.context.bindings.longitude
        );

        if (this.#map !== undefined) {
            this.#map.off();
            this.#map.remove();
        }

        this.#map = this.Leaflet.map(this.context.element.id, {
            center: latLon,
            zoom: this.context.bindings.zoomLevel,
            minZoom: 2,
            maxZoom: 18,
            layers: [this.#supportsWebGL() ? tileLayers.Satellite : tileLayers.GoogleMapsRoadmap]
        });

        markers.forEach((marker: Marker) => {
            if (this.#map) {
                marker.addTo(this.#map);
            }

            marker.addEventListener('drag', (event: LeafletEvent) => {
                const mouseEvent = event as LeafletMouseEvent;
                this.updateFormCoordinates({
                    latitude: mouseEvent.latlng.lat,
                    longitude: mouseEvent.latlng.lng
                });
            });

            document.addEventListener(googleMapEvents.newAddressCoordinates, (e) => {
                if (!this.Leaflet) {
                    return;
                }

                const latLng: LatLng = this.Leaflet.latLng(e.detail.latitude, e.detail.longitude);
                marker.setLatLng(latLng);

                this.centerMapOnMarker(this.#map, marker);
                this.updateFormCoordinates({
                    latitude: e.detail.latitude,
                    longitude: e.detail.longitude
                });
            });
        });

        this.#initialized = true;

        const mapsContainer: HTMLElement | null =
            this.context.element?.closest('[data-maps-container]');
        if (mapsContainer && this.context.bindings.showContainerWhenHidden) {
            mapsContainer.style.display = 'block';
        }

        window.dispatchEvent(new Event('resize'));
    }

    protected getMarkers(): Marker[] {
        const { latitude, longitude, draggable } = this.context.bindings;

        return [
            MapMarkerCreator.createLeafletMarker({
                lat: latitude,
                lon: longitude,
                draggable: Boolean(draggable)
            })
        ];
    }

    protected getTileLayers(): TileLayerInterface | undefined {
        if (!this.Leaflet || !this.Leaflet.mapboxGL) {
            return undefined;
        }

        const key =
            'pk.eyJ1IjoibmF0dXVyaHVpc2plIiwiYSI6ImNrNnVsZWF0bDBhNTkzaW1zZ3cxYzBtazcifQ.UlK5rfiMtNwQ4hWdcKSB5w';

        const satelliteLayer: Layer = this.Leaflet.mapboxGL({
            accessToken: key,
            style: 'mapbox://styles/natuurhuisje/ck6upa0im0mnq1il9nxrjh9ir'
        });

        const googleMapsSatelliteLayer: GridLayer = this.Leaflet.gridLayer.googleMutant({
            type: 'satellite'
        });

        const googleMapsRoadmapLayer: GridLayer = this.Leaflet.gridLayer.googleMutant({
            type: 'roadmap',
            styles: [
                { featureType: 'road', elementType: 'labels', stylers: [{ visibility: 'off' }] },
                { featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'off' }] }
            ]
        });

        return {
            Satellite: satelliteLayer,
            GoogleMapsRoadmap: googleMapsRoadmapLayer,
            GoogleMapsSatellite: googleMapsSatelliteLayer
        };
    }

    updateFormCoordinates(coordinates: { latitude: number; longitude: number }): void {
        if (this.latitudeField) {
            this.latitudeField.value = coordinates.latitude.toString(10);
        }

        if (this.longitudeField) {
            this.longitudeField.value = coordinates.longitude.toString(10);
        }
    }

    centerMapOnMarker(map: L.Map | undefined, marker: Marker): void {
        const latLons: LatLng[] = [marker.getLatLng()];
        const markerBounds: LatLngBounds | undefined = this.Leaflet?.latLngBounds(latLons);

        if (markerBounds && map) {
            map.fitBounds(markerBounds);
        }
    }

    public async initializeLeaflet(): Promise<typeof L> {
        await import(/* webpackChunkName: "asy0nc-leaflet" */ 'leaflet/dist/leaflet.css');
        await import(
            /* webpackChunkName: "async-mapbox-gl-leaflet" */ 'mapbox-gl/dist/mapbox-gl.css'
        );
        const { default: Leaflet } = await import(
            /* webpackChunkName: "async-leaflet" */ 'leaflet'
        );
        await import(/* webpackChunkName: "async-mapbox-gl-leaflet" */ 'mapbox-gl-leaflet');
        await import(
            // somehow eslint does not recognize the import
            // eslint-disable-next-line import/no-unresolved
            /* webpackChunkName: "async-leaflet.gridlayer.googlemutant" */ 'leaflet.gridlayer.googlemutant'
        );

        return Leaflet;
    }

    #supportsWebGL(): boolean {
        if (window.WebGLRenderingContext === undefined) return false;

        try {
            const canvas = document.createElement('canvas');
            return (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')) !== null;
        } catch (e) {
            return false;
        }
    }

    static getClassName(): string {
        return 'MapsComponent';
    }
}
