import priceListTemplate from '@templates/website/detail/partials/_price-list.html.njk';
import parseStringAsHtml from '@naturehouse/nh-essentials/lib/html/parser';
import TranslationManager from '../../../../common/TranslationManager';

type DetailPriceListRendererTranslations = {
    total: string;
    includingExtraCosts: string;
};

export type DetailPriceListRendererProps = {
    promotionalDiscount: number;
    totalStayAmountWithoutDiscount: number;
    totalStayAmount: number;
};

export class DetailPriceListRenderer {
    public async render(props: DetailPriceListRendererProps): Promise<string> {
        const translations: DetailPriceListRendererTranslations = await this.#getTranslations();
        const template = parseStringAsHtml(
            priceListTemplate.render({
                ...props,
                translations: translations
            }),
            'table'
        );

        return template.outerHTML;
    }

    async #getTranslations(): Promise<DetailPriceListRendererTranslations> {
        const translationManager = await TranslationManager.getInstance();

        return {
            total: translationManager.translate('Totaal'),
            includingExtraCosts: translationManager.translate('including_taxes_fees.description')
        };
    }
}

export default new DetailPriceListRenderer();
