import convertFormdataToJsonObject from '../../util/objects';
import {
    PriceListExtraCost,
    PriceListPriceInformation
} from '../../collections/price-list/PriceListCollection';
import { ExtraCostsListRendererProps } from '../../modules/app/ExtraCostsListRenderer';
import TranslationManager from '../../common/TranslationManager';
import ExtraCostPaymentType from '../../enums';
import PriceListModel from '../price-list/PriceListModel';

enum ExtraCostsListSearchParams {
    DEFAULT_MIN = 0,
    MIN_ADULTS = 1
}

export default class ExtraCostsListModel implements PriceListModel {
    public static parseToSearchParams(formData: FormData): URLSearchParams {
        const data = convertFormdataToJsonObject(formData);

        const params = {
            arrivalDate: data.arrivalDate ?? null,
            departureDate: data.departureDate ?? null,
            numberOfAdults: (
                Number(data.adults ?? ExtraCostsListSearchParams.MIN_ADULTS) ||
                ExtraCostsListSearchParams.MIN_ADULTS
            ).toString(),
            numberOfChildren: (data.children ?? ExtraCostsListSearchParams.DEFAULT_MIN).toString(),
            numberOfBabies: (data.babies ?? ExtraCostsListSearchParams.DEFAULT_MIN).toString(),
            numberOfPets: (data.pets ?? ExtraCostsListSearchParams.DEFAULT_MIN).toString(),
            insurance: '0'
        };

        return new URLSearchParams(params);
    }

    public static async parseToTemplateParams(
        data: PriceListPriceInformation
    ): Promise<ExtraCostsListRendererProps> {
        const translationManager = await TranslationManager.getInstance();

        const { serviceFee, extraCosts, includedCosts } = data;
        const afterStayCosts: PriceListExtraCost[] = [];
        const optionalCosts: PriceListExtraCost[] = [];

        for (const extraCost of extraCosts) {
            if (extraCost.optional) {
                optionalCosts.push(extraCost);
                continue;
            }

            switch (extraCost.paymentType) {
                case ExtraCostPaymentType.AFTER_STAY_COSTS:
                    afterStayCosts.push(extraCost);
                    break;
                default:
                    includedCosts.push(extraCost.name);
            }
        }

        return {
            serviceFee,
            includedCosts: includedCosts.map((includedCost) =>
                translationManager.translate(includedCost)
            ),
            afterStayCosts: afterStayCosts.map((extraCost) => ({
                ...extraCost,
                name: translationManager.translate(extraCost.name),
                priceType: translationManager.translate(extraCost.priceType)
            })),
            optionalCosts: optionalCosts.map((optionalCost) => ({
                ...optionalCost,
                name: translationManager.translate(optionalCost.name),
                priceType: translationManager.translate(optionalCost.priceType)
            }))
        };
    }
}
