var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["./extra-costs-list-content.macro.njk"] = require( "./extra-costs-list-content.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["templates/components/molecules/extra-costs-list.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("./extra-costs-list-content.macro.njk", false, "templates/components/molecules/extra-costs-list.html.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("extraCostsListContent", t_1);
output += "\n\n<div class=\"extra-costs-list\">\n    ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "includedCosts")) == 0 && env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "afterStayCosts")) == 0 && env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "optionalCosts")) == 0 && !runtime.contextOrFrameLookup(context, frame, "serviceFee")) {
output += "\n        <div>\n            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"priceIsIncluding"), env.opts.autoescape);
output += "\n        </div>\n    ";
;
}
output += "\n\n    ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "includedCosts")) > 0 || runtime.contextOrFrameLookup(context, frame, "serviceFee") > 0) {
output += "\n        <div>\n            ";
var t_4;
t_4 = (function() {
var output = "";
output += "\n                ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "includedCosts")) > 0) {
output += "\n                    ";
frame = frame.push();
var t_7 = runtime.contextOrFrameLookup(context, frame, "includedCosts");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("cost", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n                        <dt>";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += "</dt>\n                    ";
;
}
}
frame = frame.pop();
output += "\n                ";
;
}
output += "\n                ";
if(runtime.contextOrFrameLookup(context, frame, "serviceFee") > 0) {
output += "\n                    <dt>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"serviceFee"), env.opts.autoescape);
output += "</dt>\n                ";
;
}
output += "\n            ";
;
return output;
})()
;
frame.set("includedCostsContent", t_4, true);
if(frame.topLevel) {
context.setVariable("includedCostsContent", t_4);
}
if(frame.topLevel) {
context.addExport("includedCostsContent", t_4);
}
output += "\n            ";
output += runtime.suppressValue((lineno = 21, colno = 43, runtime.callWrap(runtime.memberLookup((t_1),"render"), "extraCostsListContent[\"render\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"priceIncludes"),runtime.contextOrFrameLookup(context, frame, "includedCostsContent"),false])), env.opts.autoescape);
output += "\n        </div>\n    ";
;
}
output += "\n\n    ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "afterStayCosts")) > 0) {
output += "\n        <div>\n            ";
var t_9;
t_9 = (function() {
var output = "";
output += "\n                ";
frame = frame.push();
var t_12 = runtime.contextOrFrameLookup(context, frame, "afterStayCosts");
if(t_12) {t_12 = runtime.fromIterator(t_12);
var t_11 = t_12.length;
for(var t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10];
frame.set("cost", t_13);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
output += "\n                    <dt>";
output += runtime.suppressValue(runtime.memberLookup((t_13),"name"), env.opts.autoescape);
output += "</dt>\n                    <dd>\n                        ";
output += runtime.suppressValue(env.getFilter("format_currency").call(context, (runtime.memberLookup((t_13),"price") / 100),"EUR",{},runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "app")),"request")),"locale")), env.opts.autoescape);
output += "\n                        <span class=\"extra-costs-list__price-type\">\n                            ";
output += runtime.suppressValue(runtime.memberLookup((t_13),"priceType"), env.opts.autoescape);
output += "\n                        </span>\n                    </dd>\n                ";
;
}
}
frame = frame.pop();
output += "\n            ";
;
return output;
})()
;
frame.set("afterStayCostsContent", t_9, true);
if(frame.topLevel) {
context.setVariable("afterStayCostsContent", t_9);
}
if(frame.topLevel) {
context.addExport("afterStayCostsContent", t_9);
}
output += "\n            ";
output += runtime.suppressValue((lineno = 38, colno = 43, runtime.callWrap(runtime.memberLookup((t_1),"render"), "extraCostsListContent[\"render\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"additionalCosts"),runtime.contextOrFrameLookup(context, frame, "afterStayCostsContent"),true])), env.opts.autoescape);
output += "\n        </div>\n    ";
;
}
output += "\n\n    ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "optionalCosts")) > 0) {
output += "\n        <div>\n            ";
var t_14;
t_14 = (function() {
var output = "";
output += "\n                ";
frame = frame.push();
var t_17 = runtime.contextOrFrameLookup(context, frame, "optionalCosts");
if(t_17) {t_17 = runtime.fromIterator(t_17);
var t_16 = t_17.length;
for(var t_15=0; t_15 < t_17.length; t_15++) {
var t_18 = t_17[t_15];
frame.set("cost", t_18);
frame.set("loop.index", t_15 + 1);
frame.set("loop.index0", t_15);
frame.set("loop.revindex", t_16 - t_15);
frame.set("loop.revindex0", t_16 - t_15 - 1);
frame.set("loop.first", t_15 === 0);
frame.set("loop.last", t_15 === t_16 - 1);
frame.set("loop.length", t_16);
output += "\n                    <dt>";
output += runtime.suppressValue(runtime.memberLookup((t_18),"name"), env.opts.autoescape);
output += "</dt>\n                    <dd>\n                        ";
output += runtime.suppressValue(env.getFilter("format_currency").call(context, (runtime.memberLookup((t_18),"price") / 100),"EUR",{},runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "app")),"request")),"locale")), env.opts.autoescape);
output += "\n                        <span class=\"extra-costs-list__price-type\">\n                            ";
output += runtime.suppressValue(runtime.memberLookup((t_18),"priceType"), env.opts.autoescape);
output += "\n                        </span>\n                    </dd>\n                ";
;
}
}
frame = frame.pop();
output += "\n            ";
;
return output;
})()
;
frame.set("optionalCostsContent", t_14, true);
if(frame.topLevel) {
context.setVariable("optionalCostsContent", t_14);
}
if(frame.topLevel) {
context.addExport("optionalCostsContent", t_14);
}
output += "\n            ";
output += runtime.suppressValue((lineno = 55, colno = 43, runtime.callWrap(runtime.memberLookup((t_1),"render"), "extraCostsListContent[\"render\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"optionalCosts"),runtime.contextOrFrameLookup(context, frame, "optionalCostsContent"),false])), env.opts.autoescape);
output += "\n        </div>\n    ";
;
}
output += "\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["templates/components/molecules/extra-costs-list.html.njk"] , dependencies)