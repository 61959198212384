var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["templates/components/molecules/extra-costs-list-content.macro.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["title", "content", "hasTightSpacing"], 
[], 
function (l_title, l_content, l_hasTightSpacing, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("title", l_title);
frame.set("content", l_content);
frame.set("hasTightSpacing", l_hasTightSpacing);
var t_2 = "";t_2 += "\n    ";
var t_3;
t_3 = ["nh-dl","nh-dl--horizontal"];
frame.set("classes", t_3, true);
if(frame.topLevel) {
context.setVariable("classes", t_3);
}
if(frame.topLevel) {
context.addExport("classes", t_3);
}
t_2 += "\n    ";
if(env.getTest("defined").call(context, l_hasTightSpacing) === true && l_hasTightSpacing == true) {
t_2 += "\n        ";
var t_4;
t_4 = env.getFilter("merge").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),["nh-dl--tight-spacing"]);
frame.set("classes", t_4, true);
if(frame.topLevel) {
context.setVariable("classes", t_4);
}
if(frame.topLevel) {
context.addExport("classes", t_4);
}
t_2 += "\n    ";
;
}
else {
t_2 += "\n        ";
var t_5;
t_5 = env.getFilter("merge").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),["nh-dl--no-spacing"]);
frame.set("classes", t_5, true);
if(frame.topLevel) {
context.setVariable("classes", t_5);
}
if(frame.topLevel) {
context.addExport("classes", t_5);
}
t_2 += "\n    ";
;
}
t_2 += "\n\n    <span class=\"extra-costs-list__title\">\n        ";
t_2 += runtime.suppressValue(l_title, env.opts.autoescape);
t_2 += "\n    </span>\n    <dl class=\"";
t_2 += runtime.suppressValue(env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "classes")," "), env.opts.autoescape);
t_2 += "\">\n        ";
t_2 += runtime.suppressValue(env.getFilter("safe").call(context, l_content), env.opts.autoescape);
t_2 += "\n    </dl>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("render");
context.setVariable("render", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["templates/components/molecules/extra-costs-list-content.macro.njk"] , dependencies)