import { marker, icon, latLng } from 'leaflet';
import type { Icon, Marker } from 'leaflet';

type LeafletMarkerType = {
    lat: number;
    lon: number;
    draggable: boolean;
};

export default class MapMarkerCreator {
    public static createLeafletIcon(): Icon {
        return icon({
            iconUrl: 'https://assets.nature.house/design-system/icons/custom/nh-marker.svg',
            iconSize: [32, 32],
            iconAnchor: [16, 16],
            popupAnchor: [0, -16]
        });
    }

    public static createLeafletMarker({ lat, lon, draggable }: LeafletMarkerType): Marker {
        return marker(latLng(lat, lon), {
            icon: this.createLeafletIcon(),
            draggable
        });
    }
}
